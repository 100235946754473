import Vuex from 'vuex'
import Vue from 'vue'

import mutations from './mutations/mutations'
import actions from './actions/actions'
import getters from './getters/getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    now: null,
    username: '',
    account: null,
    vendor: null,
    notifications: [],
    countNewMessages: 0,
    sessions: [],
    session: null,
    step: null,
    lesson: null,
    essay: null,
    quiz: null,
    lecture: null,
    maxFilesize: 0,
    permissions: null,
    isOpenSteps: false,
    isFullView: false,
    meetings: [],
    surveys: [],
    countNewSurveys: 0,
    library: [],
    librarySettings: [],
    libraryCatalogs: null,
    libraryCurrentCatalog: [],
    libraryFiles: [],
    extendedSearch: null,
    paginationPager: {},
    courseBooks: [],
    vendorLimits: {},
    countNewNews: 0,
  },

  mutations,
  actions,
  getters
})
